import React from 'react';
import PropTypes from 'prop-types';

import Layout from '../components/layout'

import {GatsbyLink} from "../utils";

import './404.scss'

const ErrorPage = () => {

    return(
        <Layout className='error-page'>
            <section>
                <div className='error-code'>
                    <h1>404</h1>
                </div>
                <div className='error-text'>
                    <h2 className='small-text'>This page could not be found.</h2>
                </div>

                <div>
                    <GatsbyLink href='/' className='small-text'>Return to Home</GatsbyLink>
                </div>
            </section>
        </Layout>
    )
}

ErrorPage.propTypes = {
    data: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired
}

ErrorPage.defaultProps = {
}

export default ErrorPage